function MarkerWithLabel(opts) {
  this.opts = opts;
  this.setMap(opts.map);
  this.tail = opts.tail;
}

MarkerWithLabel.prototype = new google.maps.OverlayView();

MarkerWithLabel.prototype.getPosition = function () {
  var projection = this.getProjection();
  if (projection) {
    var point = projection.fromLatLngToDivPixel(this.opts.position);
    return projection.fromDivPixelToLatLng(new google.maps.Point(point.x, point.y - 16));
  } else {
    return this.opts.position;
  }
};

MarkerWithLabel.prototype.setVisible = function (visible) {
  if (visible) {
    jQuery(this.div).removeClass('hidden');
  } else {
    jQuery(this.div).addClass('hidden');
  }
};

MarkerWithLabel.prototype.updateContent = function () {
  this.iconDiv.innerHTML = '<img src="' + this.opts.icon + '" class="'
  + (this.opts.important && this.opts.important === true  ? 'blink' : '') + '" />';
  var label = this.opts.label;
  if (label) {
    if (typeof label === 'object') {
      this.labelDiv.innerHTML = label.text;
    } else {
      this.labelDiv.innerHTML = label;
    }
  }
};

MarkerWithLabel.prototype.updateOpts = function (opts) {
  if (this.div != undefined) {
    Object.assign(this.opts, opts);
    this.tail = opts.tail;
    this.updateContent();
    this.draw();
  }
};

MarkerWithLabel.prototype.onAdd = function () {
  this.div = document.createElement('div');
  this.div.className = 'map-marker';

  this.contentDiv = document.createElement('div');
  this.contentDiv.className = 'marker-content';

  this.iconDiv = document.createElement('div');
  this.iconDiv.className = 'icon';
  this.iconDiv.classList.add(this.opts.class);
  this.iconDiv.style.marginLeft = this.opts.marginLeft;
  this.iconDiv.style.marginTop = this.opts.marginTop;
  this.iconDiv.style.marginBottom = this.opts.marginBottom;
  this.iconDiv.style.marginRight = this.opts.marginRight;
  this.iconDiv.style.position = this.opts.iconPosition;
  this.iconDiv.style.zIndex = this.opts.zIndex;
  this.iconDiv.style.opacity = this.opts.opacity;
  this.contentDiv.appendChild(this.iconDiv);

  if (this.opts.label) {
    this.labelDiv = document.createElement('div');
    this.labelDiv.style.width = this.opts.label.width;
    this.labelDiv.style.position = this.opts.label.position;
    this.labelDiv.style.marginTop = this.opts.label.marginTop;
    this.labelDiv.style.marginLeft = this.opts.label.marginLeft;
    this.labelDiv.style.zIndex = this.opts.label.zIndex;
    this.labelDiv.className = this.opts.labelVisible ? 'label' : 'label collapse';
    this.contentDiv.appendChild(this.labelDiv);
  }
  this.div.appendChild(this.contentDiv);

  this.updateContent();

  var self = this;
  this.clickListener = google.maps.event.addDomListener(this.iconDiv, "click", function (e) {
    google.maps.event.trigger(self, "click");
    e.preventDefault();
  });

  this.onMouseOver = google.maps.event.addDomListener(this.iconDiv, "mouseover", function (e) {
    google.maps.event.trigger(self, "mouseover");
    e.preventDefault();
  });

  this.onMouseOut = google.maps.event.addDomListener(this.iconDiv, "mouseout", function (e) {
    google.maps.event.trigger(self, "mouseout");
    e.preventDefault();
  });


  var panes = this.getPanes();
  panes.overlayImage.appendChild(this.div);
};

MarkerWithLabel.prototype.draw = function () {
  var point = this.getProjection().fromLatLngToDivPixel(this.opts.position);
  if (point) {
    this.div.style.left = point.x + 'px';
    this.div.style.top = point.y + 'px';
  }
};

MarkerWithLabel.prototype.onRemove = function () {
  if (this.div) {
    this.div.parentNode.removeChild(this.div);
  }
  if (this.clickListener) {
    google.maps.event.removeListener(this.clickListener);
  }

  if (this.onMouseOver) {
    google.maps.event.removeListener(this.onMouseOver);
  }

  if (this.onMouseOut) {
    google.maps.event.removeListener(this.onMouseOut);
  }

  this.div = null;
  this.contentDiv = null;
  this.iconDiv = null;
  this.labelDiv = null;
  this.tail = null;
};
