$(function () {
  $.reject({
    reject: {
      msie: 9
    },

    display: ['chrome', 'firefox'],
    browserInfo: {
      chrome: {
        text: 'Google Chrome',
        url: 'http://www.google.com/chrome/'
      },
      firefox: {
        text: 'Mozilla Firefox',
        url: 'http://www.mozilla.com/firefox/'
      }
    },

    imagePath: 'https://cdnjs.cloudflare.com/ajax/libs/jReject/1.1.4/images/',

    header: 'Unsupported browser',
    paragraph1: 'Please use one of these browsers:',
    paragraph2: '',

    close: false
  });
});
$(document).ready(function () {
  $(document).click(function (event) {
    var clickover = $(event.target);
    var _opened = $(".navbar-collapse").hasClass("show");
    var innerWidth = $(window).innerWidth();
    if (_opened === true && !clickover.hasClass("navbar-toggler") && innerWidth < 992) {
      $("button.navbar-toggler").click();
    }
  });
});
